const ID_KATEGORI_PRODUK_KECAMBAH = 'KECAMBAH';
const ID_KATEGORI_KONSUMEN_KORPORAT = 'COORPORATE';
const JABATAN_MANAGER = 'MANAGER';
const JABATAN_SUPERVISOR = 'SUPERVISOR';
const JABATAN_MARKETING = 'MARKETING';

export {
  ID_KATEGORI_PRODUK_KECAMBAH,
  ID_KATEGORI_KONSUMEN_KORPORAT,
  JABATAN_MANAGER,
  JABATAN_SUPERVISOR,
  JABATAN_MARKETING,
};
